import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./Admin.module.css";
import { useDispatch, useSelector } from "react-redux";
import LoginOrSingUp from "./Authorization/LoginOrSingUp";
import {
  addBackgroundFone,
  createNewSession,
  getAllAdminSessions,
  getAllUserSessions,
  logOut,
} from "../../DAL/dal";
import { exitAction } from "../../Redux/UserReducer";
import { CompileQuesions } from "./CompiledCaseDir/CompileCase";
import { Route, Routes, useNavigate } from "react-router-dom";
import AdminPanel from "./AmminPanel";
import SessionComponent from "./SectionDir/SessionComponent";
import Users from "./Users/Users";
import CangePassComponent from "./Authorization/CangePassComponent";
import inMemoryJWTService from "../../inMemoryJWT/inMemoryJWT";
const shortid = require("shortid");

const AdminPage = ({
  setRerender,
  reRender,
  editMode,
  setEditMode,
  editModeType,
  setEditModeType,
  height,
}) => {
  const { login, role, tariff, email } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState(
    "https://api-congress.pointreg.ru/backgrounds/default.png"
  );

  // useEffect(() => console.log(previewImage), [previewImage]);

  useEffect(() => {
    if (new Date(tariff?.end_time).getTime() <= new Date().getTime())
      console.log("тариф истек");
  }, [tariff]);

  useEffect(() => {
    if (window.location.hash.slice(0, 20) == "#/admin/change-pass/" && login)
      // чтобы если пользователь перейдет по ссылке восстановления пароля и окажется залогинен, его выбросит в главное меню админки
      navigate("/admin");
  }, [login]);

  const [isOnlyAsk, setIsOnlyAsk] = useState(false); // переключатель на только задавать вопросы
  const [allSessions, setAllSessions] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [sessionName, setSessionName] = useState("Новая сессия"); // хранит id сессии

  const [currentSessionId, setCurrentSessionId] = useState(""); // состояние нужно только для того чтобы в момент создания новой сессии передать ее id в NewSessionCreated
  const [limit, setLimit] = useState(5);
  const [onLoad, setOnLoad] = useState(false); // состояние показывающее загрузку сессий
  const [totalSessions, setTotalSessions] = useState(null);
  const [indexForEdit, setIndexForEdit] = useState(0); // индек который мы передаем в компоненту для редактирования
  const [isAsk, setIsAsk] = useState(false); // нужно ли добавлять возможность задавать вопросы
  const [colors, setColors] = useState({
    clientTextColor: "#FFFFFF",
    containerColor: "rgba(0, 0, 0, 0.5)",
    variantActive: "#00D079",
    blockNameColor: "#FFFFFF",
    questionNameColor: "#949494",
    buttonsColor: ["#2A2B9C", "#FFFFFF"],
  });
  const [alertInfo, setAlertInfo] = useState(
    "Больше 10-ти вопросов в блоке допустимо только в рамках квиза"
  );
  const [forEditMode, setForEditMode] = useState("");

  const questionObject = useMemo(() => {
    // объект блока вопросов

    const obj = {
      caseName: "Блок вопросов",
      id: "",
      questions: [
        {
          blockName: "",
          type: "questionsChoiceOfOptionsOne",
          list: [
            {
              name: "",
              isChecked: false,
            },
            {
              name: "",
              isChecked: false,
            },
          ],
          // range: { range: 0, choose: 0 },
        },
      ],
    };
    return obj;
  }, []);

  useEffect(() => {
    if (isOnlyAsk) setBlockQuestions([]);
    else setBlockQuestions([questionObject]);
  }, [isOnlyAsk]);

  const [blockQuestions, setBlockQuestions] = useState([questionObject]);
  const key = useMemo(() => shortid.generate(), [blockQuestions]);

  const initCaseNameState = useCallback(() => {
    // нумерует блоки вопросов
    setBlockQuestions((prev) =>
      prev.map((element, index) => {
        // console.log(element);
        if (element.caseName == "Блок вопросов") {
          // const key = shortid.generate();
          element = {
            caseName: `Блок вопросов ${index + 1}`,
            id: key,
            questions: [
              {
                blockName: "",
                type: "questionsChoiceOfOptionsOne",
                list: [
                  { name: "", isChecked: false, id: key },
                  { name: "", isChecked: false, id: key },
                ],
              },
            ],
          };
        }
        return element;
      })
    );
  }, [blockQuestions]);

  const sendCompileSessionData = () => {
    setOnLoad(true);
    // функция по созданию нового опросника
    let quizCheck = false;
    const sendSessionData = structuredClone(blockQuestions);
    for (let i in blockQuestions) {
      if (
        blockQuestions[i].questions.find((q) => q.type == "questionsQuizOne") ||
        blockQuestions[i].questions.find(
          (q) => q.type == "questionsQuizMany"
        ) ||
        blockQuestions[i].questions.find(
          (q) => q.type == "questionsQuizFreeText"
        )
      )
        quizCheck = true;
    }
    if (quizCheck) {
      sendSessionData.push({
        caseName: "Блок результатов квиза",
        id: shortid.generate(),
        questions: [],
      });
    }
    createNewSession(
      colors,
      fileData
        ? "https://api-congress.pointreg.ru/backgrounds/default.png" // если файл пустой, то отправляем дефолтную ссылку
        : previewImage,
      sendSessionData,
      sessionName,
      isAsk
    ) // учесть добавление имени сессии на клиенте и на сервере
      .then((r) => {
        if (fileData) {
          addBackgroundFone(fileData, r.data.sessionid).then((res) => {
            setOnLoad(false);
            setCurrentSessionId(r.data.sessionid);
            setEditModeType("session_created");
            setEditMode(true);
            const randome = Math.floor(Math.random() * 10000);
            setRerender(randome);
            setTotalSessions(null); // для возможности сделать запрос сессий на сервер
          });
        } else {
          setOnLoad(false);
          setCurrentSessionId(r.data.sessionid);
          setEditModeType("session_created");
          setEditMode(true);
          const randome = Math.floor(Math.random() * 10000);
          setRerender(randome);
          setTotalSessions(null); // для возможности сделать запрос сессий на сервер
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(initCaseNameState, [isOnlyAsk]); // один раз добавляет первому блоку пронумерованное название

  useEffect(() => {
    if (login) {
      if (role === "admin") {
        if (totalSessions > allSessions.length || totalSessions == null) {
          // учитываем что состояние при инициализации это null и тогда мы делаем первую загрузку и далее уже ориентируемся по кол ву сессий
          setOnLoad(true);
          getAllAdminSessions(limit).then((r) => {
            setTotalSessions(r.data.count);
            setAllSessions([]);
            for (let i = 0; i < r.data.sessions.length; i++) {
              setAllSessions((prev) => [
                ...prev,
                {
                  sessionName:
                    r.data.sessions[i]?.sessionname || "Новая сессия",
                  backgroundImage: r.data.sessions[i].backgroundimage,
                  sessionId: r.data.sessions[i].sessionid,
                  session_status: r.data.sessions[i].session_status,
                  createdTime: new Date(r.data.sessions[i].createdtime)
                    .toLocaleDateString()
                    .toString(),
                },
              ]);
            }
            setOnLoad(false);
          });
        }
      } else {
        getAllUserSessions(limit).then((r) => {
          setTotalSessions(r.data.count);
          setAllSessions([]);
          for (let i = 0; i < r.data.sessions.length; i++) {
            setAllSessions((prev) => [
              ...prev,
              {
                sessionName: r.data.sessions[i]?.sessionname || "Новая сессия",
                backgroundImage: r.data.sessions[i].backgroundimage,
                sessionId: r.data.sessions[i].sessionid,
                session_status: r.data.sessions[i].session_status,
                createdTime: new Date(r.data.sessions[i].createdtime)
                  .toLocaleDateString()
                  .toString(),
              },
            ]);
          }
        });
      }
    }
  }, [login, reRender, limit]);

  const redirectStylesCase = useCallback(() => {
    for (let i in blockQuestions) {
      for (let j in blockQuestions[i].questions) {
        if (
          blockQuestions[i].questions[j].type == "questionsQuizOne" ||
          blockQuestions[i].questions[j].type == "questionsQuizMany" ||
          blockQuestions[i].questions[j].type == "questionsQuizFreeText"
        ) {
          return navigate("create-session/form");
        }
      }
    }
    navigate("create-session/styles");
  }, [blockQuestions]);

  return (
    <>
      {login &&
        !editMode && ( // показываем кнопку выйти
          <button
            onClick={() => {
              // window.localStorage.removeItem("token");
              logOut()
                .then((r) => {
                  inMemoryJWTService.deleteToken();
                  dispatch(exitAction());
                })
                .catch((err) => console.log(err));
            }}
            className={styles.exit_button}
          >
            <img src={require("../../Images/log-out.png")} alt="" />
            Выйти
          </button>
        )}
      {window.location.hash == "#/admin/create-session" ? (
        login &&
        !editMode && ( // учитываем когда необходимо скрыть это
          <div
            onClick={
              redirectStylesCase
              // console.log(e.view.document.bgColor); // можно отследить и управлять событием
            }
            className={styles.step_number_two}
          >
            <h2>
              <span>→</span>Продолжить
            </h2>
            <p>
              Перейти к шагу 2 и настроить <br /> дизайн голосования
            </p>
          </div>
        )
      ) : window.location.hash == "#/admin/create-session/styles" &&
        !editMode ? (
        <div
          onClick={sendCompileSessionData}
          className={styles.step_number_two}
        >
          <h2 style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {!onLoad ? (
              <img src={require("../../Images/save.png")} alt="" />
            ) : (
              <div
                style={{ minWidth: "50px" }}
                className={styles.loading}
              ></div>
            )}
            Создать сессию
          </h2>
          <p>
            Закончить создание и перейти <br /> к списку сессий
          </p>
        </div>
      ) : window.location.hash == "#/admin/create-session/form" ? (
        <div
          onClick={() => navigate("/admin/create-session/styles")}
          className={styles.step_number_two}
        >
          <h2 style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img src={require("../../Images/save.png")} alt="" /> Далее →
          </h2>
          <p>Перейти к настройке дизайна</p>
        </div>
      ) : (
        <></>
      )}
      <section
        style={
          {
            minWidth: editMode ? "100vw" : "",
            padding: editMode ? 0 : "",
            height: height,
          }
          // editMode ? { minWidth: "100vw", padding: 0 } : login ? {} : {} // при режиме редактирования окно тянется на 100vw
        }
        className={styles.container}
      >
        {!editMode && (
          <div
            style={
              allSessions.length && login ? { margin: "0 auto 32px 0" } : {}
            }
            className={styles.logoContainer}
          >
            <img src={require("../../Images/Main Logo.png")} alt="" />

            {/** в зависимости от ситуации нужно будет менять данный h1 прямо отсюда */}
            {login && window.location.hash == "#/admin" && (
              <div className={styles.main_title_of_nav}>
                <h1 className={styles.session_title}>Сессии</h1>
                {role == "admin" ? (
                  <button
                    onClick={() => navigate("users")}
                    className={styles.users}
                  >
                    Пользователи
                  </button>
                ) : tariff &&
                  new Date(tariff.end_time).getTime() > new Date().getTime() ? ( // если у пользователя есть тариф, показываем тариф и время тарифа не прошло
                  <div>
                    <h2>Тариф: {tariff.tariff_name}</h2>
                    <p>Колличество голосований: {tariff.sessions}</p>
                    <span>
                      Действует до:{" "}
                      {new Date(tariff.end_time).toLocaleDateString()}
                    </span>
                    {/* {console.log(new Date("12-30-2023").getTime())} */}
                  </div>
                ) : tariff &&
                  new Date(tariff.end_time).getTime() <=
                    new Date().getTime() ? (
                  <div>Время действия тарифа истекло</div>
                ) : (
                  <div>У Вас нет активных тарифов</div>
                )}
              </div>
            )}
          </div>
        )}
        {!login ? ( // отсюда начинается основной роутинг
          <Routes>
            <Route
              path="/"
              element={
                <LoginOrSingUp
                  setForEditMode={setForEditMode}
                  forEditMode={forEditMode}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  setEditModeType={setEditModeType}
                  setTotalSessions={setTotalSessions}
                  editModeType={editModeType}
                />
              }
            />
            <Route
              path="change-pass/:id"
              element={
                <CangePassComponent
                  editModeType={editModeType}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  setEditModeType={setEditModeType}
                  setForEditMode={setForEditMode}
                  forEditMode={forEditMode}
                />
              }
            />
          </Routes>
        ) : (
          <Routes>
            <Route
              path="/"
              element={
                <AdminPanel
                  onLoad={onLoad}
                  setLimit={setLimit}
                  totalSessions={totalSessions}
                  reRender={reRender}
                  setRerender={setRerender}
                  role={role}
                  setAllSessions={setAllSessions}
                  allSessions={allSessions}
                  height={height}
                />
              }
            />

            <Route
              path="current-session/:id/*"
              element={
                <SessionComponent
                  shortid={shortid}
                  email={email}
                  tariff={tariff}
                  setAllSessions={setAllSessions}
                  setTotalSessions={setTotalSessions}
                  role={role}
                  reRender={reRender}
                  setRerender={setRerender}
                  setEditModeType={setEditModeType}
                  editModeType={editModeType}
                  setEditMode={setEditMode}
                  editMode={editMode}
                  questionObject={questionObject}
                  height={height}
                />
              }
            />
            <Route
              path="users/*"
              element={
                <Users
                  height={height}
                  setEditMode={setEditMode}
                  editMode={editMode}
                />
              }
            />
            <Route
              path="create-session/*"
              element={
                <>
                  <CompileQuesions
                    alertInfo={alertInfo}
                    setAlertInfo={setAlertInfo}
                    isAsk={isAsk} // добавляет возможность задавания вопрооов в принципе
                    setIsAsk={setIsAsk}
                    isOnlyAsk={isOnlyAsk}
                    setIsOnlyAsk={setIsOnlyAsk}
                    indexForEdit={indexForEdit}
                    setIndexForEdit={setIndexForEdit}
                    currentSessionId={currentSessionId}
                    setEditModeType={setEditModeType}
                    editModeType={editModeType}
                    setFileData={setFileData}
                    colors={colors}
                    setColors={setColors}
                    initCaseNameState={initCaseNameState}
                    setSessionName={setSessionName}
                    sessionName={sessionName}
                    setEditMode={setEditMode}
                    editMode={editMode}
                    blockQuestions={blockQuestions}
                    setBlockQuestions={setBlockQuestions}
                    questionObject={questionObject}
                    role={role}
                    addNewBlockForEdit={setBlockQuestions} // просто так
                    previewImage={previewImage}
                    setPreviewImage={setPreviewImage}
                    height={height}
                  />
                </>
              }
            />
          </Routes>
        )}
      </section>
    </>
  );
};

export default AdminPage;
